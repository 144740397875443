<template>
  <div class="wrap_error">
    <div class="inner_error">
      <div class="ico_error" />
      <strong class="tit_error">요청하신 페이지를 찾을 수 없습니다.</strong>
      <p class="txt_desc">입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.</p>
      <div class="wrap_btn">
        <a href="javascript:void(0);" class="btn_comm bg_type3 type_big" @click="goPrev">
          이전 페이지
        </a>
        <a href="javascript:void(0);" class="btn_comm type_big" @click="goMain">메인으로</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommNotFound",
  methods: {
    goPrev() {
      this.$router.go(-1);
    },
    goMain() {
      document.location.href = "/auth/login";
    },
  },
};
</script>
<style scoped>
.wrap_error {
  display: table;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  table-layout: fixed;
}
.wrap_error .inner_error {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.wrap_error .ico_error {
  display: inline-block;
  width: 90px;
  height: 90px;
  background: url(/assets/images/rtn/ico_etc.png) 0 0 no-repeat;
  background-size: 300px 100px;
  vertical-align: top;
}
.wrap_error .tit_error {
  display: block;
  padding-top: 36px;
  font-size: 30px;
  line-height: 45px;
  color: #1a1a1a;
}
.wrap_error .txt_desc {
  padding-top: 2px;
  font-size: 18px;
  line-height: 27px;
  color: #777;
}
.wrap_error .wrap_btn {
  padding-top: 67px;
}
.wrap_error .btn_comm {
  display: inline-block;
  height: 40px;
  line-height: 38px;
  box-sizing: border-box;
  text-decoration: none;
}
</style>
